<div class="row mb-2 justify-content-center " style="padding-top: 100px;width: 100%;margin: 0px;">
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">About Us page Sections Table </h5>
          <button  class="btn btn-sm" style="color: white;background-color: #ceb98c;" [disabled]="this.checkList.length != 0" (click)="addAboutsection()">Add New Sction </button>
         
        </div>
      </div>
    </div>

    <div     *ngFor="let section of aboutSectionList">
      <div class="col-lg-12" style="margin-bottom: 50px;">
        <div class="card ">
          <div class="card-body ">
            <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Vision Section Table </h5>
            <div class="table-responsive ">
              <table class="table center-aligned-table">
                <thead>
                  <tr class="text-primary">
                    <th>Vision Title</th>
                    <th>Vision Paragraph</th>
                    <th>Vision Image</th>
  
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td>{{section.ourVisionTitle}}</td>
                    <td style="width: 30em; margin: 0.5em; white-space: normal; vertical-align: top; display: inline-block;line-break: auto; ">{{section.ourVisionParagraph}}</td>  
                    <td><img [src]="createImgPath(section.ourVisionImage)" width="300" height="300"></td>  
  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12" style="margin-bottom: 50px;">
        <div class="card ">
          <div class="card-body ">
            <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Mision Section Table </h5>
            <div class="table-responsive ">
              <table class="table center-aligned-table">
                <thead>
                  <tr class="text-primary">
                    <th>Mision Title</th>
                    <th>Mision Paragraph</th>
                    <th>Mision Image</th>
  
                  </tr>
                </thead>
                <tbody>
                  <tr >
                    <td>{{section.ourMisionTitle}}</td>
                    <td style="width: 30em; margin: 0.5em; white-space: normal; vertical-align: top; display: inline-block;line-break: auto; ">{{section.ourMisionParagraph}}</td>  
                    <td><img [src]="createImgPath(section.ourMisionImage)" width="300" height="300"></td>  
  
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row " style="align-items: center;" *ngIf="section.isArabic == false;">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
         <button style="    float: left;width: 200px;padding: 10px;font-size: medium;    border: none;border-radius: 5px;" class="btn-primary" (click)="updateAboutSections()" >Update</button>
         <button style="float: right;width: 200px;padding: 10px;font-size: medium; border: none;border-radius: 5px;" class="btn-danger" (click)="deleteAboutSections()">Delete</button>
        </div>
      </div>
      <div class="row " style="align-items: center;"  *ngIf="section.isArabic == true;">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
         <button style="    float: left;width: 200px;padding: 10px;font-size: medium;    border: none;border-radius: 5px;" class="btn-primary" (click)="updateAboutSectionAR()" >Update</button>
         <button style="float: right;width: 200px;padding: 10px;font-size: medium; border: none;border-radius: 5px;" class="btn-danger" (click)="deleteAboutSections()">Delete</button>
        </div>
      </div>

    </div>
  
</div>
 
  