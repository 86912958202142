import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { aboutus } from '../Models/aboutus';

@Injectable({
  providedIn: 'root'
})
export class AboutusService {

  constructor(private http: HttpClient) { }
  url_about='https://api.complete-energy.co/api/AboutUs';
  ngOnInit() {          
  }
  addAboutSctions(aboutus : aboutus)
  {
    return this.http.post(this.url_about,aboutus);
  } 
  getAboutSections(){
    return this.http.get(this.url_about);
  }
  deleteAboutSections(id:any): Observable<any> {
    return this.http.delete(`${this.url_about}/${id}`);
  }
  updateAboutSections(id:any,aboutus:any): Observable<any> {
    return this.http.put(`${this.url_about}/${id}`,aboutus);
  }
  getAboutSectionByID(id:any): Observable<any> {
    return this.http.get(`${this.url_about}/${id}`);
  }
  
}
