import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {

  constructor(private router:Router) {    localStorage.setItem('valid','flase')
}

  ngOnInit(): void {
    localStorage.setItem('valid','flase')

  }

  submit(data) {
    if(data.value.email == "Completeenergy" && data.value.password=="C123456789" ){
      localStorage.setItem('valid','true')

      this.router.navigate(['/Home'])
      .then(() => {
        window.location.reload();
      });
    }
    else{
      localStorage.setItem('valid','flase')

      this.router.navigate(['/'])
      .then(() => {
        window.location.reload();
      });
    }

}
}
