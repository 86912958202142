import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from 'src/app/Services/home.service';

@Component({
  selector: 'app-update-homesection',
  templateUrl: './update-homesection.component.html',
  styleUrls: ['./update-homesection.component.css']
})
export class UpdateHomesectionComponent implements OnInit {

  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  submitted = false;
  constructor(private http: HttpClient, private homeServices:HomeService , private router:Router , private route: ActivatedRoute,) { }
  currentSections = null;

  ngOnInit(): void {
    this.getHomeSections(this.route.snapshot.paramMap.get('id'));

  }
  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;
  List =[]
  index : number;
  List2 =[]
  index2 : number; 
  List3 =[]
  index3 : number;
   List4 =[]
  index4 : number;
  getHomeSections(id): void {
    this.homeServices.gethomeSectionByID(id)
      .subscribe(
        section => {
          this.currentSections = section;
        },
        error => {
        });
  }
  updateHomeSections(): void {
    this.List= this.currentSections.sectionOneServicesImageOneLeft.split('\\');
    this.index = this.currentSections.sectionOneServicesImageOneLeft.split('\\').length
    this.currentSections.sectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];


    // this.List2= this.currentSections.sectionOneServicesImageTwoRight.split('\\');
    // this.index2 = this.currentSections.sectionOneServicesImageTwoRight.split('\\').length
    // this.currentSections.sectionOneServicesImageTwoRight = "Resources/images/"+this.List[this.index-1];


    // this.List3= this.currentSections.sectionOneServicesImageThreeRight.split('\\');
    // this.index3 = this.currentSections.sectionOneServicesImageThreeRight.split('\\').length
    // this.currentSections.sectionOneServicesImageThreeRight = "Resources/images/"+this.List[this.index-1];


    this.List4= this.currentSections.sectionThreeAboutUsImage.split('\\');
    this.index4 = this.currentSections.sectionThreeAboutUsImage.split('\\').length
    this.currentSections.sectionThreeAboutUsImage = "Resources/images/"+this.List4[this.index4-1];



    this.homeServices.updateHomeSections(this.currentSections.id, this.currentSections)
      .subscribe(
        response => {
          this.List=[];
          this.List4=[];
          alert("the sections updated");
          
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/Home']);
        },
        error => {
        });
  }
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
   

    this.http.post('https://api.complete-energy.co/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  allproduct =null
  onCreate()
  {
    this.allproduct = {
      productImagePath :this.response.dbPath,
    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }

}
