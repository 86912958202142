export class images{
    constructor(
        public  BusinessImage :string,
        

        
    ){}


    
}