import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AboutusService } from 'src/app/Services/aboutus.service';
import { HomeService } from 'src/app/Services/home.service';

@Component({
  selector: 'app-add-aboutus',
  templateUrl: './add-aboutus.component.html',
  styleUrls: ['./add-aboutus.component.css']
})
export class AddAboutusComponent implements OnInit {
  
  public progress: number;
  public message: string;
    @Output() public onUploadFinished = new EventEmitter();
  aboutsection={
    OurVisionTitle :'',
    OurVisionParagraph :'',
    OurVisionImage:'',
    OurMisionTitle:'',
    OurMisionParagraph:'',
    OurMisionImage :'',
    isArabic:false
  };
  submitted = false;

  aboutsectionAR={
    OurVisionTitle :'',
    OurVisionParagraph :'',
    OurVisionImage:'',
    OurMisionTitle:'',
    OurMisionParagraph:'',
    OurMisionImage :'',
    isArabic:true
  };

  constructor(private http: HttpClient,private aboutservices:AboutusService , private router:Router) { }

  ngOnInit(): void {  
  //   if(localStorage.getItem('valid')== 'flase'){
  //   this.router.navigate(['/LogIn'])
  //   .then(() => {
  //     window.location.reload();
  //   });
  // }
  }
  
 

  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;
  List =[]
  index : number;
  List2 =[]
  index2 : number;
  OnSubmit() :void{
    
 const data={
      
      OurVisionTitle :this.aboutsection.OurVisionTitle,
    OurVisionParagraph :this.aboutsection.OurVisionParagraph,
    OurVisionImage:this.aboutsection.OurVisionImage,
    OurMisionTitle:this.aboutsection.OurMisionTitle,
    OurMisionParagraph:this.aboutsection.OurMisionParagraph,
    OurMisionImage :this.aboutsection.OurMisionImage,
    isArabic:false
  };
  
   this.List= data.OurMisionImage.split('\\');
    this.index = data.OurMisionImage.split('\\').length
    data.OurMisionImage = "Resources/images/"+this.List[this.index-1];
    
    this.List2= data.OurVisionImage.split('\\');
    this.index2 = data.OurVisionImage.split('\\').length
    data.OurVisionImage = "Resources/images/"+this.List2[this.index2-1];
    

    this.aboutservices.addAboutSctions(data).subscribe();
    this.List=[];
    this.List2=[];

    alert("The english section added")

  }

  addArabicSection():void{
    
      const data={
           
           OurVisionTitle :this.aboutsectionAR.OurVisionTitle,
         OurVisionParagraph :this.aboutsectionAR.OurVisionParagraph,
         OurVisionImage:this.aboutsectionAR.OurVisionImage,
         OurMisionTitle:this.aboutsectionAR.OurMisionTitle,
         OurMisionParagraph:this.aboutsectionAR.OurMisionParagraph,
         OurMisionImage :this.aboutsectionAR.OurMisionImage,
         isArabic:true
       };
       
        this.List= data.OurMisionImage.split('\\');
         this.index = data.OurMisionImage.split('\\').length
         data.OurMisionImage = "Resources/images/"+this.List[this.index-1];
         
         this.List2= data.OurVisionImage.split('\\');
         this.index2 = data.OurVisionImage.split('\\').length
         data.OurVisionImage = "Resources/images/"+this.List2[this.index2-1];
         
     
         this.aboutservices.addAboutSctions(data).subscribe();
         this.List=[];
         this.List2=[];
     
         this.router.navigate(['/aboutus'])
         .then(() => {
           window.location.reload();
         });
       
  }
  public uploadFile = (files ) => {

    if (files.length === 0) {
      return;
    }
    let fileToUpload = files;
    const formData = new FormData();
   
    formData.append('file', fileToUpload, fileToUpload.name);
  

    this.http.post('https://api.complete-energy.co/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  allproduct =null
  onCreate()
  {
    this.allproduct = {
      productImagePath :this.response.dbPath,
    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }

  
}