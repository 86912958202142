import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BuisnessService } from 'src/app/Services/buisness.service';
import { ServicesSevtionService } from 'src/app/Services/services-sevtion.service';

@Component({
  selector: 'app-add-buisness',
  templateUrl: './add-buisness.component.html',
  styleUrls: ['./add-buisness.component.css']
})
export class AddBuisnessComponent implements OnInit  {
  
  public progress: number;
  public message: string;
    @Output() public onUploadFinished = new EventEmitter();

  submitted = false;

  

  constructor(private http: HttpClient,private BuisnessServices:BuisnessService , private router:Router) { }

  ngOnInit(): void {
  }
  
 

  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;
  List =[]
  index : number;
  OnSubmit(form : NgForm) :void{
    
    this.List= form.value.BusinessImageCoverVideo.split('\\');
    this.index = form.value.BusinessImageCoverVideo.split('\\').length
    form.value.BusinessImageCoverVideo = "Resources/images/"+this.List[this.index-1];
    form.value.isArabic=false
    this.BuisnessServices.addBuisnessSctions(form.value).subscribe();
    alert("English section added")

    this.List=[]
  }

  OnSubmitArabic(form : NgForm) :void{
    
    this.List= form.value.BusinessImageCoverVideo.split('\\');
    this.index = form.value.BusinessImageCoverVideo.split('\\').length
    form.value.BusinessImageCoverVideo = "Resources/images/"+this.List[this.index-1];
    form.value.isArabic=true
    this.BuisnessServices.addBuisnessSctions(form.value).subscribe();
    
    this.router.navigate(['/Buisness'])
    .then(() => {
      window.location.reload();
    });
    this.List=[]
  }
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    

    this.http.post('https://api.complete-energy.co/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  allproduct =null
  onCreate()
  {
    this.allproduct = {
      productImagePath :this.response.dbPath,
    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }

  
}