import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FAQService } from 'src/app/Services/faq.service';

@Component({
  selector: 'app-update-faq',
  templateUrl: './update-faq.component.html',
  styleUrls: ['./update-faq.component.css']
})
export class UpdateFAQComponent implements OnInit   {

  constructor( private FAQServices:FAQService , private router:Router , private route: ActivatedRoute,) { }
  currentSections = null;

  ngOnInit(): void {
    this.getFAQSections(this.route.snapshot.paramMap.get('id'));

  }
  getFAQSections(id): void {
    this.FAQServices.getFAQSectionByID(id)
      .subscribe(
        section => {
          this.currentSections = section;
        },
        error => {
        });
  }
  updateFAQSections(): void {

    this.FAQServices.updateFAQSections(this.currentSections.id, this.currentSections)
      .subscribe(
        response => {
          alert("the sections updated");
          
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/FAQ']);
        },
        error => {
        });
  }


}
