import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicesSevtionService } from 'src/app/Services/services-sevtion.service';

@Component({
  selector: 'app-update-service',
  templateUrl: './update-service.component.html',
  styleUrls: ['./update-service.component.css']
})
export class UpdateServiceComponent implements OnInit  {

  
  public progress: number;
  public message: string;
    @Output() public onUploadFinished = new EventEmitter();

  submitted = false;
  
  constructor(private http: HttpClient, private serrviceServices:ServicesSevtionService , private router:Router , private route: ActivatedRoute,) { }
  currentSections = null;

  ngOnInit(): void {
    this.getServiceSections(this.route.snapshot.paramMap.get('id'));

  }
  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;

  List =[]
  index : number;
  //OurServicesImage

  getServiceSections(id): void {
    this.serrviceServices.getServicesSectionByID(id)
      .subscribe(
        section => {
          this.currentSections = section;
        },
        error => {
        });
  }
  updateServiceSections(): void {

    this.List= this.currentSections.ourServicesImage.split('\\');
    this.index = this.currentSections.ourServicesImage.split('\\').length
    this.currentSections.ourServicesImage = "Resources/images/"+this.List[this.index-1];


    this.serrviceServices.updateServicesSections(this.currentSections.id, this.currentSections)
      .subscribe(
        response => {
          alert("the sections updated");
          this.List=[]
          
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/Services']);
        },
        error => {
        });
  }
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    

    this.http.post('https://api.complete-energy.co/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  allproduct =null
  onCreate()
  {
    this.allproduct = {
      productImagePath :this.response.dbPath,
    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }


}

