<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

  <div class="row justify-content-center content-my-account">
      <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
            <div *ngIf="showSuccess; else thenfaild">
              <div class="alert alert-success" role="alert">
                  Section Added
              </div>
             </div>
             <ng-template #thenfaild></ng-template>
            
            <div *ngIf="showError; else elseBlock">
              <div class="alert alert-warning" role="alert">
                  {{errorMessage.error | json}}
              </div>
            </div>
            <ng-template #elseBlock></ng-template>
            <h2 style="padding-bottom: 20px;">Add Frequent Asked Quwstion Sections</h2>
           <form #faqform="ngForm" (ngSubmit)="OnSubmit(faqform)">
              <div class="form-holder">
                  <p style="margin-top: 30px; ">Question <span style="color: #6b7a42;"> *</span></p>
                  
                  <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  
                   [class.is-invalid]="Question.invalid && Question.touched " required  #Question="ngModel" [(ngModel)]="faqform.Question" name="Question"></textarea>
                  <small class="text-danger"[class.d-none]="Question.untouched || Question.valid">Question is required</small>
                </div>
            <div class="form-holder">
              <p style="margin-top: 30px; ">Answer <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  
               [class.is-invalid]="Answer.invalid && Answer.touched " required  #Answer="ngModel" [(ngModel)]="faqform.Answer" name="Answer"></textarea>
              <small class="text-danger"[class.d-none]="Answer.untouched || Answer.valid">Answer</small>
            </div>
        

         
              <button type="submit"  class="bt" style="background-color: burlywood;
              color: aliceblue;
              border: none;
              padding: 10px 40px 10px 40px;
              font-size: medium;
              text-align: unset;
              margin-top: 40px;"  [disabled]="!faqform.valid">Add</button>

              </form>
              <br>  
              <!-- <div class="row justify-content-center content-my-account">
                  <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
              </div>               -->
              
      </div>
  </div>
</div>
<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

  <div class="row justify-content-center content-my-account">
      <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
            <div *ngIf="showSuccess; else thenfaild">
              <div class="alert alert-success" role="alert">
                  Section Added
              </div>
             </div>
             <ng-template #thenfaild></ng-template>
            
            <div *ngIf="showError; else elseBlock">
              <div class="alert alert-warning" role="alert">
                  {{errorMessage.error | json}}
              </div>
            </div>
            <ng-template #elseBlock></ng-template>
            <h2 style="padding-bottom: 20px;">اضافه اللغه العربيه</h2>
           <form #faqform2="ngForm" (ngSubmit)="OnSubmitArabic(faqform2)">
              <div class="form-holder">
                  <p style="margin-top: 30px; ">ســــــؤال <span style="color: #6b7a42;"> *</span></p>
                  
                  <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  
                   [class.is-invalid]="Question.invalid && Question.touched " required  #Question="ngModel" [(ngModel)]="faqform2.Question" name="Question"></textarea>
                  <small class="text-danger"[class.d-none]="Question.untouched || Question.valid">Question is required</small>
                </div>
            <div class="form-holder">
              <p style="margin-top: 30px; ">جـــواب <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  
               [class.is-invalid]="Answer.invalid && Answer.touched " required  #Answer="ngModel" [(ngModel)]="faqform2.Answer" name="Answer"></textarea>
              <small class="text-danger"[class.d-none]="Answer.untouched || Answer.valid">Answer</small>
            </div>
        

         
              <button type="submit"  class="bt" style="background-color: burlywood;
              color: aliceblue;
              border: none;
              padding: 10px 40px 10px 40px;
              font-size: medium;
              text-align: unset;
              margin-top: 40px;"  [disabled]="!faqform2.valid">اضافه</button>

              </form>
              <br>  
              <!-- <div class="row justify-content-center content-my-account">
                  <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
              </div>               -->
              
      </div>
  </div>
</div>