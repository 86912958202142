import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AboutusService } from 'src/app/Services/aboutus.service';

@Component({
  selector: 'app-updateabout',
  templateUrl: './updateabout.component.html',
  styleUrls: ['./updateabout.component.css']
})
export class UpdateaboutComponent implements OnInit {

  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();
  submitted = false;


  constructor(private http: HttpClient, private aboutusServices:AboutusService , private router:Router , private route: ActivatedRoute,) { }
  currentSections = null;

  ngOnInit(): void {
    this.getAboutSections(this.route.snapshot.paramMap.get('id'));

  }
  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;
  List =[]
  index : number;
  List2 =[]
  index2 : number;
  getAboutSections(id): void {
    this.aboutusServices.getAboutSectionByID(id)
      .subscribe(
        section => {
          this.currentSections = section;
        },
        error => {
        });
  }
  updateAboutSections(): void {
    this.List= this.currentSections.ourVisionImage.split('\\');
    this.index = this.currentSections.ourVisionImage.split('\\').length
    this.currentSections.ourVisionImage = "Resources/images/"+this.List[this.index-1];


    this.List2= this.currentSections.ourMisionImage.split('\\');
    this.index2 = this.currentSections.ourMisionImage.split('\\').length
    this.currentSections.ourMisionImage = "Resources/images/"+this.List2[this.index2-1];


    this.aboutusServices.updateAboutSections(this.currentSections.id, this.currentSections)
      .subscribe(
        response => {
          this.List=[];
          this.List2=[];
          alert("the sections updated");
          
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/aboutus']);
        },
        error => {
        });
  }
  public uploadFile = (files ) => {

    if (files.length === 0) {
      return;
    }
    let fileToUpload = files;
    const formData = new FormData();
    
    formData.append('file', fileToUpload, fileToUpload.name);
  

    this.http.post('https://api.complete-energy.co/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  allproduct =null
  onCreate()
  {
    this.allproduct = {
      productImagePath :this.response.dbPath,
    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }



}

