<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

  <div class="row justify-content-center content-my-account">
      <div class="col-md-6 col-sm-12 col-12 content-my-account-right"  *ngIf="currentSections">
          
        
            <div class="form-holder">
                <p>Header Title <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="HeaderTitle"
                [(ngModel)]="currentSections.headerTitle"
                name="HeaderTitle" >
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">Header Pragraph <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
              id="HeaderParagraph"
              [(ngModel)]="currentSections.headerParagraph"
              name="HeaderParagraph"></textarea>
            </div>

            <h2 style="padding-bottom: 20px; margin-top:40px">Add Service Section(1) </h2>

            <div class="form-holder">
                <p style="margin-top: 30px;">Service Title <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  
                 id="SectionOneServicesTitle"
                [(ngModel)]="currentSections.sectionOneServicesTitle"
                name="SectionOneServicesTitle">
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">Service Pragraph <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control" 
                id="SectionOneServicesParagraph"
              [(ngModel)]="currentSections.sectionOneServicesParagraph"
              name="SectionOneServicesParagraph"></textarea>
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">Upload Image One <span style="color: #6b7a42;"> *</span></p>
              <!-- (change)="uploadFile(file.files)" -->
              <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                  <input 
                  id="upload"type="file" 
                  class="form-control border-0" #file type="file" 
                  (change)="uploadFile($event.target.files[0])" multiple 
                                    [(ngModel)]="currentSections.sectionOneServicesImageOneLeft"
                  name="SectionOneServicesImageOneLeft" >
              </div>
             </div>

              
             <h2 style="padding-bottom: 20px; margin-top:40px">Add Contact Section(2) </h2>

             <div class="form-holder">
                 <p style="margin-top: 30px;">Works Hour <span style="color: #6b7a42;"> *</span></p>
                 <input type="text" class="form-control"  
                  id="SectionTwoWorksHour"
                 [(ngModel)]="currentSections.sectionTwoWorksHour"
                 name="SectionTwoWorksHour">
             </div>
             <div class="form-holder">
              <p style="margin-top: 30px;">Adress <span style="color: #6b7a42;"> *</span></p>
              <input type="text" class="form-control"  
               id="SectionTwoGetDirection"
              [(ngModel)]="currentSections.sectionTwoGetDirection"
              name="SectionTwoGetDirection">
          </div>
          <div class="form-holder">
              <p style="margin-top: 30px;">Call Us <span style="color: #6b7a42;"> *</span></p>
              <input type="text" class="form-control" 
               id="SectionTwoCallUs"
              [(ngModel)]="currentSections.sectionTwoCallUs"
              name="SectionTwoCallUs" >
          </div>
          <h2 style="padding-bottom: 20px; margin-top:40px">Add About Us Section(3) </h2>

          <div class="form-holder">
              <p style="margin-top: 30px;">About Us Title <span style="color: #6b7a42;"> *</span></p>
              <input type="text" class="form-control"  
                id="SectionThreeAboutUsTitle"
              [(ngModel)]="currentSections.sectionThreeAboutUsTitle"
              name="SectionThreeAboutUsTitle">
          </div>

          <div class="form-holder">
            <p style="margin-top: 30px;">About Us Pragraph <span style="color: #6b7a42;"> *</span></p>
            
            <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
            id="SectionThreeAboutUsParagraph"
            [(ngModel)]="currentSections.sectionThreeAboutUsParagraph"
            name="SectionThreeAboutUsParagraph"></textarea>
          </div>

          <div class="form-holder">
            <p style="margin-top: 30px;">About us Image  <span style="color: #6b7a42;"> *</span></p>
            <!-- (change)="uploadFile(file.files)" -->
            <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                <input 
                id="upload2"type="file" 
                class="form-control border-0" #file type="file" 
                (change)="uploadFile($event.target.files[0])" multiple 
                [(ngModel)]="currentSections.sectionThreeAboutUsImage"
                name="SectionThreeAboutUsImage">
            </div>
           </div>

              <button type="submit"  class="bt" style="background-color: burlywood;
              color: aliceblue;
              border: none;
              padding: 10px 40px 10px 40px;
              font-size: medium;
              text-align: unset;
              margin-top: 40px;" routerLink="/Home" (click)="updateHomeSections()">Update</button>
              <br>  
        
      </div>
  </div>
</div>