import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { buisness } from 'src/app/Models/buisness';
import { BuisnessService } from 'src/app/Services/buisness.service';

@Component({
  selector: 'app-buisness',
  templateUrl: './buisness.component.html',
  styleUrls: ['./buisness.component.css']
})
export class BuisnessComponent implements OnInit  {


  constructor(private BuisnessService:BuisnessService , private router:Router) {

   }
  
  BuisnessSections:buisness[]=[];
  BuisnessSectionList:buisness[]=[];

  CurrentBuisnessSection =null;
  ngOnInit(): void {
     this.GetAllBuisnesssSection();
  }
  GetAllBuisnesssSection()
  {
    this.BuisnessService.getBuisnessSections().subscribe((data:any)=>{
      this.BuisnessSections = data;
 
      this.BuisnessSections.forEach(section => {
        
          this.BuisnessSectionList.push(section);
      });
    });
  }
  addBuisnesssections(){
    this.router.navigate(['/addBuisnessection']);

  }
  addImagesection(){
    this.router.navigate(['/addImagesection']);

  }
  viewImagesection(){
    this.router.navigate(['/viewImagesection']);

  }
  deleteBuisnessSections(id) {
    this.BuisnessService.deleteBuisnessSections(id).subscribe();
    alert("the sections deleted");
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/Buisness']);
  }
  
  updateBuisnessSections(id): void {
    this.router.navigate(['/updateBuisnessections',id]);
      // this.aboutBuisness.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }
    public createImgPath = (serverPath: string) => {
      return `https://api.complete-energy.co/${serverPath}`;
    }
    public response: {dbPath: ''};
    onCreate(){
   this.CurrentBuisnessSection={
    BusinessImageCoverVideo:this.response.dbPath
      }
    }
    public uploadFinished = (event) => {
      this.response = event;
    }
}
