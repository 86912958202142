<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right"  *ngIf="currentSections">
          <h2 style="padding-bottom: 20px; margin-top:40px">Add Section Section</h2>

          
              <div class="form-holder">
                  <p>Service Title <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"    
                  id="OurServicesTitle"
                  [(ngModel)]="currentSections.ourServicesTitle"
                  name="OurServicesTitle" >
              </div>
  
              <div class="form-holder">
                <p style="margin-top: 30px;">Service Pragraph <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
                id="OurServicesParagraph"
                [(ngModel)]="currentSections.ourServicesParagraph"
                name="OurServicesParagraph"></textarea>
              </div>
  
  
              <div class="form-holder">
                <p style="margin-top: 30px;">Upload Image  <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file"  class="form-control border-0" #file type="file" multiple 
                    [(ngModel)]="currentSections.ourServicesImage"
                    name="OurServicesImage" >
                </div>
               </div>
            

  
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;" routerLink="/Services" (click)="updateServiceSections()">Update</button>
                <br>  
          
        </div>
    </div>
  </div>