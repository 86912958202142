import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { contactus } from 'src/app/Models/contactus';
import { ContactService } from 'src/app/Services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit{


  constructor(private ContactService:ContactService , private router:Router) {

   }
  
  ContactSections:contactus[]=[];
  ContactSectionList:contactus[]=[];
  checkList:contactus[]=[]


  CurrentContactSection =null;
  ngOnInit(): void {
     this.GetAllContactsSection();
  }
  GetAllContactsSection()
  {
    this.ContactService.getContactSections().subscribe((data:any)=>{
      this.ContactSections = data;
      this.checkList=data;
      this.ContactSections.forEach(section => {
        
          this.ContactSectionList.push(section);
      });
    });
  }
  addContactsections(){
    this.router.navigate(['/addContactsections']);

  }
  
  deleteContactSections(id) {
    this.ContactService.deleteContactSections(id).subscribe();
    alert("the sections deleted");
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/Contact']);
  }
  
  updateContactections(id): void {
    this.router.navigate(['/updateContactections',id]);
      // this.aboutCoontact.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }
}


