<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                    Section Added
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">Add Images</h2>
             <form #imageform="ngForm" (ngSubmit)="OnSubmit(imageform)">
           

           
            <div class="form-holder">
                <p style="margin-top: 30px;">Upload Image  <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file"  class="form-control border-0" #file  (change)="uploadFile(file.files)" 
                    multiple  required #BusinessImage="ngModel" [(ngModel)]="imageform.BusinessImage" name="BusinessImage">
                </div>
                <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
               </div>
           
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  [disabled]="!imageform.valid">Add</button>

                </form>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>