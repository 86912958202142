<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                    Section Added
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">Add Services Sections</h2>
             <form #servicesform="ngForm" (ngSubmit)="OnSubmit(servicesform)">
              <div class="form-holder">
                  <p>Services Title <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"  
                  [class.is-invalid]="OurServicesTitle.invalid && OurServicesTitle.touched " required #OurServicesTitle="ngModel" [(ngModel)]="servicesform.OurServicesTitle" name="OurServicesTitle">
                  <small class="text-danger"[class.d-none]="OurServicesTitle.untouched || OurServicesTitle.valid">Title is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">Services Paragraph <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  
                 [class.is-invalid]="OurServicesParagraph.invalid && OurServicesParagraph.touched " required  #OurServicesParagraph="ngModel" [(ngModel)]="servicesform.OurServicesParagraph" name="OurServicesParagraph"></textarea>
                <small class="text-danger"[class.d-none]="OurServicesParagraph.untouched || OurServicesParagraph.valid">OurServicesParagraph is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">Upload Image  
                    <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file" 
                     class="form-control border-0" #file type="file" 
                    (change)="uploadFile(file.files)" multiple
                     required #OurServicesImage="ngModel" [(ngModel)]="servicesform.OurServicesImage" name="OurServicesImage"> >
                </div>
                <small style="margin-left: 15px;" class="text-danger">This OurServicesImage is required</small>
               </div>

           
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  [disabled]="!servicesform.valid">Add</button>

                </form>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>
<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                    Section Added
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">اضافه جزء اللغه العربيه</h2>
             <form #servicesform2="ngForm" (ngSubmit)="OnSubmitArabic(servicesform2)">
              <div class="form-holder">
                  <p>العنوان <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"  
                  [class.is-invalid]="OurServicesTitle.invalid && OurServicesTitle.touched " required #OurServicesTitle="ngModel" [(ngModel)]="servicesform2.OurServicesTitle" name="OurServicesTitle">
                  <small class="text-danger"[class.d-none]="OurServicesTitle.untouched || OurServicesTitle.valid">Title is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">الفقره <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  
                 [class.is-invalid]="OurServicesParagraph.invalid && OurServicesParagraph.touched " required  #OurServicesParagraph="ngModel" [(ngModel)]="servicesform2.OurServicesParagraph" name="OurServicesParagraph"></textarea>
                <small class="text-danger"[class.d-none]="OurServicesParagraph.untouched || OurServicesParagraph.valid">OurServicesParagraph is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">تحميل الصوره
                    <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file" 
                     class="form-control border-0" #file type="file" 
                    (change)="uploadFile(file.files)" multiple
                     required #OurServicesImage="ngModel" [(ngModel)]="servicesform2.OurServicesImage" name="OurServicesImage"> >
                </div>
                <small style="margin-left: 15px;" class="text-danger">This OurServicesImage is required</small>
               </div>

           
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  [disabled]="!servicesform2.valid">اضافه</button>

                </form>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>