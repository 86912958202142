<div class="container-fluid">
    <div class="row row-offcanvas row-offcanvas-right">
  <nav class="bg-white sidebar sidebar-offcanvas" id="sidebar" style="padding-top: 60px;height: 1000px">    
      <div class="user-info">
        <img src="../../../assets/images/faces/Icon_light.png" alt="">
        <p class="name">Energy Site Admin</p>
        <p class="designation">Administraror</p>
      </div>
      <ul class="nav" style="padding-top: 0px;">
        <li class="nav-item ">
          <a class="nav-link" routerLink="/Home">
            <img src="../../../assets/images/icons/1.png" alt="">
            <span class="menu-title">Home Page</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/aboutus">
            <img src="../../../assets/images/icons/009-maps-and-flags.png" alt="">
  
            <span class="menu-title">About Us</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/Services">
            <img src="../../../assets/images/icons/007-star.png" alt="">
  
            <span class="menu-title">Our Services</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/Buisness">
            <img src="../../../assets/images/icons/4.png" alt="">
  
            <span class="menu-title">Business</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/Contact">
            <img src="../../../assets/images/icons/002-placeholder.png" alt="">
            <span class="menu-title">Contact Us</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/FAQ">
            <img src="../../../assets/images/icons/015-notification.png" alt="">
            <span class="menu-title">Common Question</span>
            </a>
        </li>
      
      </ul>
    </nav>
    </div>
  </div>