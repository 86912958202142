import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FAQ } from 'src/app/Models/FAQ';
import { FAQService } from 'src/app/Services/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {


  constructor(private FAQService:FAQService , private router:Router) {

   }
  
  FAQSections:FAQ[]=[];
  FAQSectionList:FAQ[]=[];

  CurrentFAQSection =null;
  ngOnInit(): void {
     this.GetAllFAQsSection();
  }
  GetAllFAQsSection()
  {
    this.FAQService.getFAQSections().subscribe((data:any)=>{
      this.FAQSections = data;
 
      this.FAQSections.forEach(section => {
        
          this.FAQSectionList.push(section);
      });
    });
  }
  addFAQsections(){
    this.router.navigate(['/addFAQsections']);

  }
  
  deleteFAQSections(id) {
    this.FAQService.deleteFAQSections(id).subscribe();
    alert("the sections deleted");
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/FAQ']);
  }
  
  updateFAQections(id): void {
    this.router.navigate(['/updateFAQections',id]);
      // this.aboutFAQ.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }
}

