import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ContactService } from 'src/app/Services/contact.service';

@Component({
  selector: 'app-update-contact',
  templateUrl: './update-contact.component.html',
  styleUrls: ['./update-contact.component.css']
})
export class UpdateContactComponent implements OnInit   {

  constructor( private ContactServices:ContactService , private router:Router , private route: ActivatedRoute,) { }
  currentSections = null;

  ngOnInit(): void {
    this.getContactSections(this.route.snapshot.paramMap.get('id'));

  }
  getContactSections(id): void {
    this.ContactServices.getContactSectionByID(id)
      .subscribe(
        section => {
          this.currentSections = section;
        },
        error => {
        });
  }
  updateContactSections(): void {

    this.ContactServices.updateContactSections(this.currentSections.id, this.currentSections)
      .subscribe(
        response => {
          alert("the sections updated");
          
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/Contact']);
        },
        error => {
        });
  }


}
