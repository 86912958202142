<div class="row mb-2 justify-content-center " style="padding-top: 100px;width: 100%;margin: 0px;">
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Buisness Section Table </h5>
          <button  class="btn btn-sm" style="color: white;background-color: #ceb98c; " (click)="addImagessections()">Add New Sction </button>

          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Buisness Images</th>
                  <th>delete</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let section of ImageSectionList">
                  <td><img [src]="createImgPath(section.businessImage)" width="400" height="400"></td>  

                
                 <td>
                 <button style=" border: none;border-radius: 5px;" class="btn-danger" (click)="deleteImagesSections(section.id)">Delete</button>

                 </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
   
  </div>
  <div class="row " style="align-items: center;">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
  </div>
  </div>
  