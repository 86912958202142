<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                    Section Added
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">Add Vision Section</h2>

              <div class="form-holder">
                  <p>Vision Title <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"  [class.is-invalid]="Title.invalid && Title.touched " [class.is-invalid]="Title.invalid && Title.touched " required #Title="ngModel" [(ngModel)]="aboutsection.OurVisionTitle" name="Title">
                  <small class="text-danger"[class.d-none]="Title.untouched || Title.valid">Title is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">Vision Pragraph <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="Pragraph.invalid && Pragraph.touched " [class.is-invalid]="Pragraph.invalid && Pragraph.touched " required #Pragraph="ngModel" [(ngModel)]="aboutsection.OurVisionParagraph" name="Pragraph"></textarea>
                <small class="text-danger"[class.d-none]="Pragraph.untouched || Pragraph.valid">Pragraph is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">Upload Image 
                   <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file" 
                     class="form-control border-0" #file type="file"
                      (change)="uploadFile($event.target.files[0])" multiple
                        [(ngModel)]="aboutsection.OurVisionImage" name="aboutsection.OurVisionImage"  >
                </div>
                <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
               </div>

               <h2 style="padding-bottom: 20px;">Add Mission Section</h2>

               <div class="form-holder">
                   <p>Mission Title <span style="color: #6b7a42;"> *</span></p>
                   <input type="text" class="form-control"  [class.is-invalid]="Title.invalid && Title.touched " [class.is-invalid]="Title.invalid && Title.touched " required #Title="ngModel" [(ngModel)]="aboutsection.OurMisionTitle" name="Title">
                   <small class="text-danger"[class.d-none]="Title.untouched || Title.valid">Title is required</small>
               </div>
 
               <div class="form-holder">
                 <p style="margin-top: 30px;">Mission Pragraph <span style="color: #6b7a42;"> *</span></p>
                 
                 <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="Pragraph.invalid && Pragraph.touched " [class.is-invalid]="Pragraph.invalid && Pragraph.touched " required #Pragraph="ngModel" [(ngModel)]="aboutsection.OurMisionParagraph" name="Pragraph"></textarea>
                 <small class="text-danger"[class.d-none]="Pragraph.untouched || Pragraph.valid">Pragraph is required</small>
               </div>
 
               <div class="form-holder">
                 <p style="margin-top: 30px;">Upload Image  <span style="color: #6b7a42;"> *</span></p>
                 <!-- (change)="uploadFile(file.files)" -->
                 <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                     <input  id="upload2" type="file" 
                     class="form-control border-0" #file type="file"
                     name="aboutsection.OurMisionImage"
                     (change)="uploadFile($event.target.files[0])" 
                       [(ngModel)]="aboutsection.OurMisionImage" observeFiles  >
                 </div>
                 <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
                </div>
        
           


                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  (click)="OnSubmit()">Add</button>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>

<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

  <div class="row justify-content-center content-my-account">
      <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
            <div *ngIf="showSuccess; else thenfaild">
              <div class="alert alert-success" role="alert">
                  Section Added
              </div>
             </div>
             <ng-template #thenfaild></ng-template>
            
            <div *ngIf="showError; else elseBlock">
              <div class="alert alert-warning" role="alert">
                  {{errorMessage.error | json}}
              </div>
            </div>
            <ng-template #elseBlock></ng-template>
            <h2 style="padding-bottom: 20px;">اضافه الجزء الاول  الرؤيا</h2>

            <div class="form-holder">
                <p>العنوان <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  [class.is-invalid]="Title.invalid && Title.touched "
                 [class.is-invalid]="Title.invalid && Title.touched " required #Title="ngModel"
                  [(ngModel)]="aboutsectionAR.OurVisionTitle" name="Title">
                <small class="text-danger"[class.d-none]="Title.untouched || Title.valid">Title is required</small>
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">الفقره <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="Pragraph.invalid && Pragraph.touched " [class.is-invalid]="Pragraph.invalid && Pragraph.touched " required #Pragraph="ngModel" [(ngModel)]="aboutsectionAR.OurVisionParagraph" name="Pragraph"></textarea>
              <small class="text-danger"[class.d-none]="Pragraph.untouched || Pragraph.valid">Pragraph is required</small>
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">تحميل الصوره
                 <span style="color: #6b7a42;"> *</span></p>
              <!-- (change)="uploadFile(file.files)" -->
              <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                  <input id="upload" type="file" 
                   class="form-control border-0" #file type="file"
                    (change)="uploadFile($event.target.files[0])" multiple
                      [(ngModel)]="aboutsectionAR.OurVisionImage" name="aboutsectionAR.OurVisionImage"  >
              </div>
              <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
             </div>

             <h2 style="padding-bottom: 20px;">اضافه الجزء الثاني  المهمه</h2>

             <div class="form-holder">
                 <p>العنوان<span style="color: #6b7a42;"> *</span></p>
                 <input type="text" class="form-control"  [class.is-invalid]="Title.invalid && Title.touched " [class.is-invalid]="Title.invalid && Title.touched " required #Title="ngModel" [(ngModel)]="aboutsectionAR.OurMisionTitle" name="Title">
                 <small class="text-danger"[class.d-none]="Title.untouched || Title.valid">Title is required</small>
             </div>

             <div class="form-holder">
               <p style="margin-top: 30px;">الفقره <span style="color: #6b7a42;"> *</span></p>
               
               <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="Pragraph.invalid && Pragraph.touched " [class.is-invalid]="Pragraph.invalid && Pragraph.touched " required #Pragraph="ngModel" [(ngModel)]="aboutsectionAR.OurMisionParagraph" name="Pragraph"></textarea>
               <small class="text-danger"[class.d-none]="Pragraph.untouched || Pragraph.valid">Pragraph is required</small>
             </div>

             <div class="form-holder">
               <p style="margin-top: 30px;">تحميل الصوره <span style="color: #6b7a42;"> *</span></p>
               <!-- (change)="uploadFile(file.files)" -->
               <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                   <input  id="upload2" type="file" 
                   class="form-control border-0" #file type="file"
                   name="aboutsectionAR.OurMisionImage"
                   (change)="uploadFile($event.target.files[0])" 
                     [(ngModel)]="aboutsection.OurMisionImage" observeFiles  >
               </div>
               <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
              </div>
      
         


              <button type="submit"  class="bt" style="background-color: burlywood;
              color: aliceblue;
              border: none;
              padding: 10px 40px 10px 40px;
              font-size: medium;
              text-align: unset;
              margin-top: 40px;"  (click)="addArabicSection()">اضافه</button>
              <br>  
              <!-- <div class="row justify-content-center content-my-account">
                  <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
              </div>               -->
              
      </div>
  </div>
</div>