import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { services } from 'src/app/Models/services';
import { ServicesSevtionService } from 'src/app/Services/services-sevtion.service';

@Component({
  selector: 'app-our-service',
  templateUrl: './our-service.component.html',
  styleUrls: ['./our-service.component.css']
})
export class OurServiceComponent implements OnInit  {


  constructor(private Servicesservices:ServicesSevtionService , private router:Router) {

   }
  
  serviceSections:services[]=[];
  ServicesSectionList:services[]=[];

  CurrentServiceSection =null;
  ngOnInit(): void {
     this.GetAllServicesSection();
  }
  GetAllServicesSection()
  {
    this.Servicesservices.getServicesSections().subscribe((data:any)=>{
      this.serviceSections = data;
 
      this.serviceSections.forEach(section => {
        
          this.ServicesSectionList.push(section);
          this.CurrentServiceSection=section;
      });
    });
  }
  addServicessections(){
    this.router.navigate(['/addServicesection']);

  }
  deleteServiceSections(id) {
    this.Servicesservices.deleteServicesSections(id).subscribe();
    alert("the sections deleted");
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/Services']);
  }
  
  updateServiceSections(id): void {
    this.router.navigate(['/updateServiceSections',id]);
      // this.aboutservices.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }

    public createImgPath = (serverPath: string) => {
      return `https://api.complete-energy.co/${serverPath}`;
    }
    public response: {dbPath: ''};
    onCreate(){
   this.CurrentServiceSection={
    ourServicesImage:this.response.dbPath
      }
    }
    public uploadFinished = (event) => {
      this.response = event;
    }
}
