import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { aboutus } from 'src/app/Models/aboutus';
import { AboutusService } from 'src/app/Services/aboutus.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit  {


  constructor(private aboutservices:AboutusService , private router:Router) {

   }
  
  aboutSections:aboutus[]=[];
  aboutSectionList:aboutus[]=[];
  checkList:aboutus[]=[]

  id1:number;
  id2:number;
    CurrentAboutSection =null;
  ngOnInit(): void {
  //    if(localStorage.getItem('valid')== 'flase'){
  //   this.router.navigate(['/LogIn'])
  //   .then(() => {
  //     window.location.reload();
  //   });
  // }
  
     this.GetAllAboutSection();
  
  }
  GetAllAboutSection()
  {
    this.aboutservices.getAboutSections().subscribe((data:any)=>{
      this.aboutSections = data;
      this.checkList=data;
      this.id1=data[0].id;
      this.id2=data[1].id;
      this.aboutSections.forEach(section => {
        
          this.aboutSectionList.push(section);
          this.CurrentAboutSection=section
      });
    });
  }
  addAboutsection(){
    this.router.navigate(['/addaboutsection']);

  }
  deleteAboutSections() {
    this.aboutservices.deleteAboutSections(this.id1).subscribe();
    this.aboutservices.deleteAboutSections(this.id2).subscribe();

    alert("the sections deleted");
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/aboutus']);
  }
  
  updateAboutSections(): void {
    this.router.navigate(['/updateaboutsection',this.id1]);
      // this.aboutservices.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }
    updateAboutSectionAR(): void {
      this.router.navigate(['/updateaboutsection',this.id2]);
        // this.aboutservices.updateCategory(this.id, this.CurrentHomrSection)
        //   .subscribe()
       
      }
    public createImgPath = (serverPath: string) => {
      return `https://api.complete-energy.co/${serverPath}`;
    }
    public response: {dbPath: ''};
    onCreate(){
   this.CurrentAboutSection={
    ourVisionImage:this.response.dbPath,
    ourMisionImage:this.response.dbPath

      }
    }
    public uploadFinished = (event) => {
      this.response = event;
    }
}
