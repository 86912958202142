<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right"  *ngIf="currentSections">
          <h2 style="padding-bottom: 20px; margin-top:40px">Add Vision Section</h2>

          
              <div class="form-holder">
                  <p>Vision Title <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"    
                  id="OurVisionTitle"
                  [(ngModel)]="currentSections.ourVisionTitle"
                  name="OurVisionTitle" >
              </div>
  
              <div class="form-holder">
                <p style="margin-top: 30px;">Vision Pragraph <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
                id="OurVisionParagraph"
                [(ngModel)]="currentSections.ourVisionParagraph"
                name="OurVisionParagraph"></textarea>
              </div>
  
  
              <div class="form-holder">
                <p style="margin-top: 30px;">Upload Image  <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file" 
                     class="form-control border-0"
                      #file type="file" 
                      (change)="uploadFile($event.target.files[0])" multiple 
                    [(ngModel)]="currentSections.ourVisionImage"
                    name="OurVisionImage" >
                </div>
               </div>
               <h2 style="padding-bottom: 20px; margin-top:40px">Add Mission Section </h2>

               <div class="form-holder">
                <p>Mission Title <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="OurMisionTitle"
                [(ngModel)]="currentSections.ourMisionTitle"
                name="OurMisionTitle" >
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">Mision Pragraph <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
              id="OurVisionParagraph"
              [(ngModel)]="currentSections.ourMisionParagraph"
              name="OurMisionParagraph"></textarea>
            </div>


            <div class="form-holder">
              <p style="margin-top: 30px;">Upload Image  <span style="color: #6b7a42;"> *</span></p>
              <!-- (change)="uploadFile(file.files)" -->
              <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                  <input id="upload2" type="file" 
                   class="form-control border-0"
                    #file type="file" 
                    (change)="uploadFile($event.target.files[0])" multiple 
                  [(ngModel)]="currentSections.ourMisionImage"
                  name="OurMisionImage" >
              </div>
             </div>
  
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;" routerLink="/aboutus" 
                (click)="updateAboutSections()">Update</button>
                <br>  
          
        </div>
    </div>
  </div>