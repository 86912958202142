
<div class="row mb-2 justify-content-center " style="padding-top: 100px;width: 100%;margin: 0px;">
  <div class="col-lg-12" style="margin-bottom: 50px;">
    <div class="card ">
      <div class="card-body ">
        <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Home Page </h5>
        <button  class="btn btn-sm" style="color: white;background-color: #ceb98c;" 
        [disabled]="this.checkList.length != 0" (click)="addhomesection()">Add New Sction </button>
        
      </div>
    </div>
  </div>
  <div *ngFor="let section of homeSectionList">
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Header Section </h5>
         
  
  
          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Header Title</th>
                  <th>Header Paragraph</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{{section.headerTitle}}</td>
                  <td style="width: 50em; margin: 0.5em; white-space: normal; vertical-align: top; display: inline-block;line-break: auto; ">{{section.headerParagraph}}</td>  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;"> Section One Table </h5>
          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Services Title</th>
                  <th>Services Paragraph</th>
  
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{{section.sectionOneServicesTitle}}</td>
                  <td style="width: 50em; margin: 0.5em; white-space: normal; vertical-align: top; display: inline-block;line-break: auto; ">{{section.sectionOneServicesParagraph}}</td> 
  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  
                  <th>Image One</th>
                  <!-- <th>Image Two</th>
                  <th>Image Three</th> -->
  
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>
                    <img [src]="createImgPath(section.sectionOneServicesImageOneLeft)" width="300" height="300">
  
                  </td>  
                  <!-- <td>
                    <img [src]="createImgPath(section.sectionOneServicesImageTwoRight)" width="300" height="300">
  
                  </td>  
                  <td>
                    <img [src]="createImgPath(section.sectionOneServicesImageThreeRight)" width="300" height="300">
  
                  </td>   -->
  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;"> Section Two Table </h5>
          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Works Hour</th>
                  <th>Adress </th>
                  <th>Call Us </th>
  
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{{section.sectionTwoWorksHour}}</td>
                  <td>{{section.sectionTwoGetDirection}}</td>  
                  <td>{{section.sectionTwoCallUs}}</td>  
  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12"  style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;"> Section Three Table </h5>
          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>AboutUs Title</th>
                  <th>AboutUs Paragraph</th>
                  <th>AboutUs Image</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{{section.sectionThreeAboutUsTitle}}</td>
                  <td style="width: 30em; margin: 0.5em; white-space: normal; vertical-align: top; display: inline-block;line-break: auto; ">{{section.sectionThreeAboutUsParagraph}}</td> 
                  <td>
                    <img [src]="createImgPath(section.sectionThreeAboutUsImage)" width="300" height="300">
  
                    
                  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row " style="align-items: center;" *ngIf="section.isArabic == false;">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
       <button style="    float: left;width: 200px;padding: 10px;font-size: medium;    border: none;border-radius: 5px;" class="btn-primary" (click)="updateHomeSections()" >Update</button>
       <button style="float: right;width: 200px;padding: 10px;font-size: medium; border: none;border-radius: 5px;" class="btn-danger" (click)="deleteHomeSections()">Delete</button>
       <!-- (click)="updateAcceptRequest()" -->
      </div>
    </div>


    <div class="row " style="align-items: center;" *ngIf="section.isArabic == true;">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
       <button style="    float: left;width: 200px;padding: 10px;font-size: medium;    border: none;border-radius: 5px;" class="btn-primary" (click)="updateHomeSectionsAR()" >Update</button>
       <button style="float: right;width: 200px;padding: 10px;font-size: medium; border: none;border-radius: 5px;" class="btn-danger" (click)="deleteHomeSections()">Delete</button>
       <!-- (click)="updateAcceptRequest()" -->
      </div>
    </div>


  </div>
 


</div>






