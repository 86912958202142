import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/Services/Contact.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit   {
  
  public progress: number;
  public message: string;
    @Output() public onUploadFinished = new EventEmitter();

  submitted = false;

  

  constructor(private http: HttpClient,private ContactServices:ContactService , private router:Router) { }

  ngOnInit(): void {
  }
  
 

  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;
  List =[]
  index : number;
  yrl="https://api.complete-energy.co/api/Upload"
  OnSubmit(form : NgForm) :void{
    
   
    // this.List= this.homepgesection.SectionOneServicesImageOneLeft.split('\\');
    // this.index = this.homepgesection.SectionOneServicesImageOneLeft.split('\\').length
    // this.homepgesection.SectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];
    form.value.isArabic=false

    this.ContactServices.addContactSctions(form.value).subscribe();
    
    alert("English section added")

  }

  OnSubmitArabic(form : NgForm) :void{
    
    form.value.isArabic=true

    // this.List= this.homepgesection.SectionOneServicesImageOneLeft.split('\\');
    // this.index = this.homepgesection.SectionOneServicesImageOneLeft.split('\\').length
    // this.homepgesection.SectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];

    this.ContactServices.addContactSctions(form.value).subscribe();
    
    this.router.navigate(['/Contact'])
    .then(() => {
      window.location.reload();
    });
  }
  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
  

    this.http.post(this.yrl, formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  allproduct =null
  onCreate()
  {
    this.allproduct = {
      productImagePath :this.response.dbPath,
    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }

  
}