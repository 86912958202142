<div class="row mb-2 justify-content-center " style="padding-top: 100px;width: 100%;margin: 0px;">
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Buisness Section Table </h5>
          <button  class="btn btn-sm" style="color: white;background-color: #ceb98c; margin-right: 20px;" (click)="addBuisnesssections()">Add New Sction </button>
          <button  class="btn btn-sm" style="color: white;background-color: #ceb98c;margin-right: 20px;" (click)="addImagesection()">Add Buisness Images</button>
          <button  class="btn btn-sm" style="color: white;background-color: #ceb98c;" (click)="viewImagesection()">View Buisness Images</button>


          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Buisness Title</th>
                  <th>Buisness Paragraph</th>
                  <th>Buisness Video Link</th>
                  <th>Cover Video image</th>
                  <th>update</th>
                  <th>delete</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let section of BuisnessSectionList">
                  <td>{{section.businessTitle}}</td>
                  <td  style="width: 30em; margin: 0.5em; white-space:normal; vertical-align: top; display: inline-block;line-break: auto; ">{{section.businessParagraph}}</td>  

                  <td style="    line-break: anywhere;
                  ">{{section.businessLinkVedio}}</td>  
                  <td><img [src]="createImgPath(section.businessImageCoverVideo)" width="200" height="200"></td>  

                 <td>   
                     <button style="border: none;border-radius: 5px;" class="btn-primary" (click)="updateBuisnessSections(section.id)" >Update</button> 
                 </td>
                 <td>
                 <button style=" border: none;border-radius: 5px;" class="btn-danger" (click)="deleteBuisnessSections(section.id)">Delete</button>

                 </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
   
  </div>
  <div class="row " style="align-items: center;">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
  </div>
  </div>
  