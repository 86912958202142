import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { homepage } from 'src/app/models/homepage';
import { HomeService } from 'src/app/Services/home.service';

@Component({
  selector: 'app-add-homesections',
  templateUrl: './add-homesections.component.html',
  styleUrls: ['./add-homesections.component.css']
})
export class AddHomesectionsComponent implements OnInit{
  
  public progress: number;
  public message: string;
    @Output() public onUploadFinished = new EventEmitter();
  homepgesection={
      HeaderTitle :'',
      HeaderParagraph :'',
      SectionOneServicesTitle:'',
      SectionOneServicesParagraph:'',
      SectionOneServicesImageOneLeft:'',
      SectionOneServicesImageTwoRight :'',
      SectionOneServicesImageThreeRight :'',
      SectionTwoWorksHour :'',
      SectionTwoGetDirection :'',
      SectionTwoCallUs:'',
      SectionThreeAboutUsTitle :'',
      SectionThreeAboutUsParagraph :'',
      SectionThreeAboutUsImage :'',
      isArabic:false
  };
  submitted = false;

  homepgesectionAR={
    HeaderTitle :'',
    HeaderParagraph :'',
    SectionOneServicesTitle:'',
    SectionOneServicesParagraph:'',
    SectionOneServicesImageOneLeft:'',
    SectionOneServicesImageTwoRight :'',
    SectionOneServicesImageThreeRight :'',
    SectionTwoWorksHour :'',
    SectionTwoGetDirection :'',
    SectionTwoCallUs:'',
    SectionThreeAboutUsTitle :'',
    SectionThreeAboutUsParagraph :'',
    SectionThreeAboutUsImage :'',
    isArabic:true
};


  

  constructor(private http: HttpClient,private homeservices:HomeService , private router:Router) { }

  ngOnInit(): void {
  }
  
 

  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;
  List =[]
  index : number;
  List2 =[]
  index2 : number;
  List3 =[]
  index3 : number;
  List4 =[]
  index4 : number;
  OnSubmit() :void{
    
    // this.List= this.homepgesection.SectionOneServicesImageOneLeft.split('\\');
    // this.index = this.homepgesection.SectionOneServicesImageOneLeft.split('\\').length
    // this.homepgesection.SectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];
    const data={
      
      HeaderTitle :this.homepgesection.HeaderTitle,
      HeaderParagraph :this.homepgesection.HeaderParagraph,
      SectionOneServicesTitle:this.homepgesection.SectionOneServicesTitle,
      SectionOneServicesParagraph:this.homepgesection.SectionOneServicesParagraph,
      SectionOneServicesImageOneLeft:this.homepgesection.SectionOneServicesImageOneLeft,
      SectionOneServicesImageTwoRight :this.homepgesection.SectionOneServicesImageTwoRight,
      SectionOneServicesImageThreeRight :this.homepgesection.SectionOneServicesImageThreeRight,
      SectionTwoWorksHour :this.homepgesection.SectionTwoWorksHour,
      SectionTwoGetDirection :this.homepgesection.SectionTwoGetDirection,
      SectionTwoCallUs:this.homepgesection.SectionTwoCallUs,
      SectionThreeAboutUsTitle :this.homepgesection.SectionThreeAboutUsTitle,
      SectionThreeAboutUsParagraph :this.homepgesection.SectionThreeAboutUsParagraph,
      SectionThreeAboutUsImage :this.homepgesection.SectionThreeAboutUsImage,
      isArabic:this.homepgesection.isArabic
  };
  this.List= data.SectionOneServicesImageOneLeft.split('\\');
  this.index = data.SectionOneServicesImageOneLeft.split('\\').length
  data.SectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];
  
  // this.List2= data.SectionOneServicesImageTwoRight.split('\\');
  // this.index2 = data.SectionOneServicesImageTwoRight.split('\\').length
  // data.SectionOneServicesImageTwoRight = "Resources/images/"+this.List2[this.index2-1];
  
  // this.List3= data.SectionOneServicesImageThreeRight.split('\\');
  // this.index3 = data.SectionOneServicesImageThreeRight.split('\\').length
  // data.SectionOneServicesImageThreeRight = "Resources/images/"+this.List2[this.index2-1];
  

  this.List4= data.SectionThreeAboutUsImage.split('\\');
  this.index4 = data.SectionThreeAboutUsImage.split('\\').length
  data.SectionThreeAboutUsImage = "Resources/images/"+this.List4[this.index4-1];
  



    this.homeservices.addHomeSctions(data).subscribe();
    this.List=[];
    this.List2=[];
    this.List3=[];
    this.List4=[];
    

    alert("The english section added")
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate(['/Home']);
  }


  addArabicSection():void{
    
    this.homepgesectionAR.isArabic=true;
      // this.List= this.homepgesectionAR.SectionOneServicesImageOneLeft.split('\\');
      // this.index = this.homepgesectionAR.SectionOneServicesImageOneLeft.split('\\').length
      // this.homepgesectionAR.SectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];
      const data={
        
        HeaderTitle :this.homepgesectionAR.HeaderTitle,
        HeaderParagraph :this.homepgesectionAR.HeaderParagraph,
        SectionOneServicesTitle:this.homepgesectionAR.SectionOneServicesTitle,
        SectionOneServicesParagraph:this.homepgesectionAR.SectionOneServicesParagraph,
        SectionOneServicesImageOneLeft:this.homepgesectionAR.SectionOneServicesImageOneLeft,
        SectionOneServicesImageTwoRight :this.homepgesectionAR.SectionOneServicesImageTwoRight,
        SectionOneServicesImageThreeRight :this.homepgesectionAR.SectionOneServicesImageThreeRight,
        SectionTwoWorksHour :this.homepgesectionAR.SectionTwoWorksHour,
        SectionTwoGetDirection :this.homepgesectionAR.SectionTwoGetDirection,
        SectionTwoCallUs:this.homepgesectionAR.SectionTwoCallUs,
        SectionThreeAboutUsTitle :this.homepgesectionAR.SectionThreeAboutUsTitle,
        SectionThreeAboutUsParagraph :this.homepgesectionAR.SectionThreeAboutUsParagraph,
        SectionThreeAboutUsImage :this.homepgesectionAR.SectionThreeAboutUsImage,
        isArabic:this.homepgesectionAR.isArabic
    };
    this.List= data.SectionOneServicesImageOneLeft.split('\\');
    this.index = data.SectionOneServicesImageOneLeft.split('\\').length
    data.SectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];
    
    // this.List2= data.SectionOneServicesImageTwoRight.split('\\');
    // this.index2 = data.SectionOneServicesImageTwoRight.split('\\').length
    // data.SectionOneServicesImageTwoRight = "Resources/images/"+this.List2[this.index2-1];
    
    // this.List3= data.SectionOneServicesImageThreeRight.split('\\');
    // this.index3 = data.SectionOneServicesImageThreeRight.split('\\').length
    // data.SectionOneServicesImageThreeRight = "Resources/images/"+this.List2[this.index2-1];
    
  
    this.List4= data.SectionThreeAboutUsImage.split('\\');
    this.index4 = data.SectionThreeAboutUsImage.split('\\').length
    data.SectionThreeAboutUsImage = "Resources/images/"+this.List4[this.index4-1];
    

  
  
      this.homeservices.addHomeSctions(data).subscribe();
      this.List=[];
      this.List2=[];
      this.List3=[];
      this.List4=[];
      
      this.router.navigate(['/Home'])
      .then(() => {
        window.location.reload();
      });
       
    }
  


  public uploadFile = (files ) => {

    if (files.length === 0) {
      return;
    }
    let fileToUpload = files;
    const formData = new FormData();
    
    formData.append('file', fileToUpload, fileToUpload.name);
  

    this.http.post('https://api.complete-energy.co/api/upload', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }
   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  allproduct =null
  onCreate()
  {
    this.allproduct = {
      productImagePath :this.response.dbPath,
    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }

 
}

