
<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" style="background-color: #ceb98c;" >
    <div class="bg-white text-center navbar-brand-wrapper" style="background-color: white;">
      <h2 class="navbar-brand brand-logo" style="line-height: 45px;color: #ceb98c;padding-top: 10px;">Energy Site</h2>
      <h4 class="navbar-brand brand-logo-mini" style="line-height: 45px;color: #ceb98c;padding-top: 10px;">E</h4>

    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center">
      <button class="navbar-toggler navbar-toggler d-none d-lg-block navbar-dark align-self-center mr-3" type="button" data-toggle="minimize">
        <span class="navbar-toggler-icon"></span>
      </button>
      <form class="form-inline mt-2 mt-md-0 d-none d-lg-block">
        <input class="form-control mr-sm-2 search" type="text" placeholder="Search">
      </form>
      <ul class="navbar-nav ml-lg-auto d-flex align-items-center flex-row">
        <li class="nav-item">
          <a class="nav-link profile-pic" href="#"><img class="rounded-circle" src="../../../assets/images/faces/Icon_light.png" alt=""></a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Logout</a>
        </li> -->
      </ul>
      <button class="navbar-toggler navbar-dark navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>