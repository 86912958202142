import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { buisness } from 'src/app/Models/buisness';
import { images } from 'src/app/Models/images';
import { ImagesService } from 'src/app/Services/images.service';

@Component({
  selector: 'app-buisness-images',
  templateUrl: './buisness-images.component.html',
  styleUrls: ['./buisness-images.component.css']
})
export class BuisnessImagesComponent implements OnInit  {


  constructor(private ImageService:ImagesService , private router:Router) {

   }
  
  ImagesSections:images[]=[];
  ImageSectionList:images[]=[];
   num:string
  CurrentImagesSection =null;
  ngOnInit(): void {
     this.GetAllImagessSection();
  }
  GetAllImagessSection()
  {
    this.ImageService.getImagesSections().subscribe((data:any)=>{
      this.ImagesSections = data;
 
      this.ImagesSections.forEach(section => {
        
          this.ImageSectionList.push(section);
          
     this.num=this.ImageSectionList.length.toString();
    localStorage.setItem('imagenumber', this.num);
    
    
      });
    });
  }
  addImagessections(){
    this.router.navigate(['/addImagesection']);

  }
  deleteImagesSections(id) {
    this.ImageService.deleteImagesSections(id).subscribe();
    alert("the sections deleted");
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/Images']);
  }
  public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  onCreate(){
 this.CurrentImagesSection={
  BusinessImage:this.response.dbPath

    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }

}
