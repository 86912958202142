import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { images } from 'src/app/Models/images';
import { BuisnessService } from 'src/app/Services/buisness.service';
import { ImagesService } from 'src/app/Services/images.service';

@Component({
  selector: 'app-add-images',
  templateUrl: './add-images.component.html',
  styleUrls: ['./add-images.component.css']
})
export class AddImagesComponent implements OnInit {
  
  public progress: number;
  public message: string;
  public imagename= new images('');
  public     path:string="Resources/images/";
public allpath:string;

    @Output() public onUploadFinished = new EventEmitter();

  submitted = false;

  

  constructor(private http: HttpClient,private ImagesServices:ImagesService , private router:Router) { }

  ngOnInit(): void {
  }
  
 

  public showSuccess: boolean =false ;
  public showError: boolean;
  public errorMessage: string;
  List =[]
  index : number;
  OnSubmit(form : NgForm) :void{

    // this.List= this.homepgesection.SectionOneServicesImageOneLeft.split('\\');
    // this.index = this.homepgesection.SectionOneServicesImageOneLeft.split('\\').length
    // this.homepgesection.SectionOneServicesImageOneLeft = "Resources/images/"+this.List[this.index-1];

    // this.ImagesServices.addImagesSctions(form.value).subscribe();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/Images']);
    
  }



  public uploadFile = (files) => {
    console.log("done")
    if (files.length === 0) {

      return;
    }
    
    let filesToUpload : File[] = files;
    const formData = new FormData();
      
    Array.from(filesToUpload).map((file, index) => {
     
this.saveimage(file.name);
      return formData.append('file'+index, file, file.name);


      
    });
  
    this.http.post('https://api.complete-energy.co/api/Upload/uploadmulti', formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
  }

 saveimage(name){
    this.allpath=this.path+name;

    this.imagename.BusinessImage=this.allpath;
     this.ImagesServices.addImagesSctions(this.imagename).subscribe();
  }
}