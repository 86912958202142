<div class="mainform">
    <p class="sign"style="text-align: center;">Sign in</p>
    <form class="form1" #myForm="ngForm" (ngSubmit)="submit(myForm)">
            <div class="form-group" style="text-align: center;">
                <input 
                type="email" 
                class="form-control un" 
                name="email" 
                placeholder="user name"
                [(ngModel)]="myForm.email" 
                #email="ngModel" 
                [ngClass]="{ 'is-invalid': myForm.submitted && email.invalid }"        
                required>
        
                <!-- Error -->
                <div *ngIf="myForm.submitted && email.invalid" class="invalid-feedback">
                <div *ngIf="email.errors.required">user name is required.</div>
                <div *ngIf="email.errors.email">Please provide valid user name.</div>
                </div>
            </div>
            
          <div class="form-group" style="text-align: center;">
            <input 
              type="password" 
              placeholder="Password"
              class=" form-control pass" 
              name="password" 
              [(ngModel)]="myForm.password" 
              #password="ngModel"
              [ngClass]="{ 'is-invalid': myForm.submitted && password.invalid }" 
              minlength="6"
              required>
      
              <!-- Error -->
              <div *ngIf="myForm.submitted && password.invalid" class="invalid-feedback">
                <div *ngIf="password.errors.required">Set a strong password.</div>
                <div *ngIf="password.errors.minlength">Minimum 6 characters required.</div>
              </div>        
          </div>
      

          <div class="form-group">
            <button class="submit" style="text-align: center;">Sign in</button>
          </div>
         </form>       
    </div>




