<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right"  *ngIf="currentSections">
          <h2 style="padding-bottom: 20px; margin-top:40px">Update Section</h2>

          
              <div class="form-holder">
                  <p>Buisness Title <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"    
                  id="BusinessTitle"
                  [(ngModel)]="currentSections.businessTitle"
                  name="BusinessTitle" >
              </div>
  
              <div class="form-holder">
                <p style="margin-top: 30px;">Buisness Pragraph <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
                id="BusinessParagraph"
                [(ngModel)]="currentSections.businessParagraph"
                name="BusinessParagraph"></textarea>
              </div>
         

              <div class="form-holder">
                <p style="margin-top: 30px;">Upload Image  <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file" 
                     class="form-control border-0"
                      #file type="file" 
                      (change)="uploadFile($event.target.files[0])" multiple 
                    [(ngModel)]="currentSections.businessImageCoverVideo"
                    name="BusinessImageCoverVideo" >
                </div>
               </div>
               
              <div class="form-holder">
                <p>Buisness Vedio Link <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="BusinessLinkVedio"
                [(ngModel)]="currentSections.businessLinkVedio"
                name="BusinessLinkVedio" >
            </div>
  
            

  
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;" routerLink="/Buisness" (click)="updateBuisnessSections()">Update</button>
                <br>  
          
        </div>
    </div>
  </div>