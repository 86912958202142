import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddHomesectionsComponent } from './pages/add-homesections/add-homesections.component';
import { UpdateHomesectionComponent } from './pages/update-homesection/update-homesection.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AddAboutusComponent } from './pages/add-aboutus/add-aboutus.component';
import { UpdateaboutComponent } from './pages/updateabout/updateabout.component';
import { OurServiceComponent } from './pages/our-service/our-service.component';
import { AddServicesComponent } from './pages/add-services/add-services.component';
import { UpdateServiceComponent } from './pages/update-service/update-service.component';
import { BuisnessComponent } from './pages/buisness/buisness.component';
import { AddBuisnessComponent } from './pages/add-buisness/add-buisness.component';
import { UpdateBuisnessComponent } from './pages/update-buisness/update-buisness.component';
import { AddImagesComponent } from './pages/add-images/add-images.component';
import { BuisnessImagesComponent } from './pages/buisness-images/buisness-images.component';
import { FAQComponent } from './pages/faq/faq.component';
import { AddFAQComponent } from './pages/add-faq/add-faq.component';
import { UpdateFAQComponent } from './pages/update-faq/update-faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AddContactComponent } from './pages/add-contact/add-contact.component';
import { UpdateContactComponent } from './pages/update-contact/update-contact.component';
import { LogInComponent } from './pages/log-in/log-in.component';

const routes: Routes = [
  // {path:'',redirectTo:'LogIn', pathMatch: 'full' },
  // {path:'LogIn',component:LogInComponent},
   {path:'',redirectTo:'Home', pathMatch: 'full' },

  {path:'Home',component:HomeComponent},
  {path:'addhomesection',component:AddHomesectionsComponent},
  {path:'updatehomesection/:id',component:UpdateHomesectionComponent},
  {path:'aboutus',component:AboutUsComponent},
  {path:'addaboutsection' , component:AddAboutusComponent},
  {path:'updateaboutsection/:id',component:UpdateaboutComponent},
  {path:'Services',component:OurServiceComponent},
  {path:'addServicesection',component:AddServicesComponent},
  {path:'updateServiceSections/:id',component:UpdateServiceComponent},
  {path:'Buisness',component:BuisnessComponent},
  {path:'addBuisnessection' , component:AddBuisnessComponent},
  {path:'updateBuisnessections/:id',component:UpdateBuisnessComponent},
  {path:'Images',component:BuisnessImagesComponent},
  {path:'addImagesection' , component:AddImagesComponent},
  {path:'viewImagesection', component:BuisnessImagesComponent},
  {path:'FAQ', component:FAQComponent},
  {path:'addFAQsections', component:AddFAQComponent},
  {path:'updateFAQections/:id',component:UpdateFAQComponent},
  {path:'Contact', component:ContactComponent},
  {path:'addContactsections', component:AddContactComponent},
  {path:'updateContactections/:id',component:UpdateContactComponent},









];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],

  exports: [RouterModule]
})
export class AppRoutingModule { }
