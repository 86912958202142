<div class="row mb-2 justify-content-center " style="padding-top: 100px;width: 100%;margin: 0px;">
  <div class="col-lg-12" style="margin-bottom: 50px;">
    <div class="card ">
      <div class="card-body ">
        <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Contact Us Page </h5>
        <button  class="btn btn-sm" style="color: white;background-color: #ceb98c; margin-right: 20px;"  [disabled]="this.checkList.length != 0" (click)="addContactsections()">Add New Sction </button>

      </div>
    </div>
  </div>
  <div   *ngFor="let section of ContactSectionList"
  > 
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">


          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Location</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                 
                </tr>
                
              </thead>
              <tbody>
                <tr >
                  <td>{{section.location}}</td>
                  <td>{{section.phoneNumber}}</td>  
                  <td>{{section.email}}</td>  
                  

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">


          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Available Time</th>
                  <th>Facebook Link</th>
                  <th>Instagram Link</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{{section.availableTime}}</td>  

                  <td>{{section.facebookLink}}</td>  
                  <td>{{section.instagramLink}}</td>  

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">


          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>LinkedIn Link</th>
                  <th>Gmail Link</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{{section.linkedInLink}}</td>  
                  <td>{{section.gmailLink}}</td>  

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">


          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  
                  <th>Map Embeded Link</th>
                </tr>
              </thead>
              <tbody>
                <tr >
                  
                  <td style="word-break: break-all;">{{section.mapIframeklink}}</td>  

                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row " style="align-items: center;" >
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
          <button style="    float: left;width: 200px;padding: 10px;font-size: medium;    border: none;border-radius: 5px;" class="btn-primary" (click)="updateContactections(section.id)" >Update</button>
          <button style="float: right;width: 200px;padding: 10px;font-size: medium; border: none;border-radius: 5px;" class="btn-danger" (click)="deleteContactSections(section.id)">Delete</button>
       
    </div>
    </div>
   
  </div> 
  </div>
  
