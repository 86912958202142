<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                    Section Added
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">Add Contact Us Sections</h2>
             <form #Contactform="ngForm" (ngSubmit)="OnSubmit(Contactform)">
           

                <div class="form-holder">
                    <p style="margin-top: 30px; ">Location <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="Location.invalid && Location.touched " required #Location="ngModel" [(ngModel)]="Contactform.Location" name="Location">
                    <small class="text-danger"[class.d-none]="Location.untouched || Location.valid">Location is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">Phone Number <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="PhoneNumber.invalid && PhoneNumber.touched " required #PhoneNumber="ngModel" [(ngModel)]="Contactform.PhoneNumber" name="PhoneNumber">
                    <small class="text-danger"[class.d-none]="PhoneNumber.untouched || PhoneNumber.valid">PhoneNumber is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">Email <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="Email.invalid && Email.touched " required #Email="ngModel" [(ngModel)]="Contactform.Email" name="Email">
                    <small class="text-danger"[class.d-none]="Email.untouched || Email.valid">Title is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">AvailableTime<span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="AvailableTime.invalid && AvailableTime.touched " required #AvailableTime="ngModel" [(ngModel)]="Contactform.AvailableTime" name="AvailableTime">
                    <small class="text-danger"[class.d-none]="AvailableTime.untouched || AvailableTime.valid">Available Time is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">Facebook Link <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="FacebookLink.invalid && FacebookLink.touched " required #FacebookLink="ngModel" [(ngModel)]="Contactform.FacebookLink" name="FacebookLink">
                    <small class="text-danger"[class.d-none]="FacebookLink.untouched || FacebookLink.valid">Facebook Link is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">Instagram Link <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="InstagramLink.invalid && InstagramLink.touched " required #InstagramLink="ngModel" [(ngModel)]="Contactform.InstagramLink" name="InstagramLink">
                    <small class="text-danger"[class.d-none]="InstagramLink.untouched || InstagramLink.valid">Title is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">LinkedInLink <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="LinkedInLink.invalid && LinkedInLink.touched " required #LinkedInLink="ngModel" [(ngModel)]="Contactform.LinkedInLink" name="LinkedInLink">
                    <small class="text-danger"[class.d-none]="LinkedInLink.untouched || LinkedInLink.valid">Linked InLink is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">Gmail Link <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="GmailLink.invalid && GmailLink.touched " required #GmailLink="ngModel" [(ngModel)]="Contactform.GmailLink" name="GmailLink">
                    <small class="text-danger"[class.d-none]="GmailLink.untouched || GmailLink.valid">Gmail Link is required</small>
                </div>
                
                <div class="form-holder">
                    <p style="margin-top: 30px; ">Map Embeded link <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="MapIframeklink.invalid && MapIframeklink.touched " required #MapIframeklink="ngModel" [(ngModel)]="Contactform.MapIframeklink" name="MapIframeklink">
                    <small class="text-danger"[class.d-none]="MapIframeklink.untouched || MapIframeklink.valid">Map Link is required</small>
                </div>
           
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  [disabled]="!Contactform.valid">Add</button>

                </form>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>

<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                    Section Added
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">اضافه اللغه العربيه</h2>
             <form #Contactform2="ngForm" (ngSubmit)="OnSubmitArabic(Contactform2)">
           

                <div class="form-holder">
                    <p style="margin-top: 30px; ">العنوان <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="Location.invalid && Location.touched " required #Location="ngModel" [(ngModel)]="Contactform2.Location" name="Location">
                    <small class="text-danger"[class.d-none]="Location.untouched || Location.valid">Location is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">رقم الهاتف <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="PhoneNumber.invalid && PhoneNumber.touched " required #PhoneNumber="ngModel" [(ngModel)]="Contactform2.PhoneNumber" name="PhoneNumber">
                    <small class="text-danger"[class.d-none]="PhoneNumber.untouched || PhoneNumber.valid">PhoneNumber is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">الايميل <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="Email.invalid && Email.touched " required #Email="ngModel" [(ngModel)]="Contactform2.Email" name="Email">
                    <small class="text-danger"[class.d-none]="Email.untouched || Email.valid">Title is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">وقت العمل<span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="AvailableTime.invalid && AvailableTime.touched " required #AvailableTime="ngModel" [(ngModel)]="Contactform2.AvailableTime" name="AvailableTime">
                    <small class="text-danger"[class.d-none]="AvailableTime.untouched || AvailableTime.valid">Available Time is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">لينك الفيسبوك<span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="FacebookLink.invalid && FacebookLink.touched " required #FacebookLink="ngModel" [(ngModel)]="Contactform2.FacebookLink" name="FacebookLink">
                    <small class="text-danger"[class.d-none]="FacebookLink.untouched || FacebookLink.valid">Facebook Link is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">لينك انستجرام<span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="InstagramLink.invalid && InstagramLink.touched " required #InstagramLink="ngModel" [(ngModel)]="Contactform2.InstagramLink" name="InstagramLink">
                    <small class="text-danger"[class.d-none]="InstagramLink.untouched || InstagramLink.valid">Title is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">لينك لينكد ان<span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="LinkedInLink.invalid && LinkedInLink.touched " required #LinkedInLink="ngModel" [(ngModel)]="Contactform2.LinkedInLink" name="LinkedInLink">
                    <small class="text-danger"[class.d-none]="LinkedInLink.untouched || LinkedInLink.valid">Linked InLink is required</small>
                </div>
                <div class="form-holder">
                    <p style="margin-top: 30px; ">الايميل <span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="GmailLink.invalid && GmailLink.touched " required #GmailLink="ngModel" [(ngModel)]="Contactform2.GmailLink" name="GmailLink">
                    <small class="text-danger"[class.d-none]="GmailLink.untouched || GmailLink.valid">Gmail Link is required</small>
                </div>
                
                <div class="form-holder">
                    <p style="margin-top: 30px; ">لينك الموقع على خريطه جوجل<span style="color: #6b7a42;"> *</span></p>
                    <input type="text" class="form-control"  
                    [class.is-invalid]="MapIframeklink.invalid && MapIframeklink.touched " required #MapIframeklink="ngModel" [(ngModel)]="Contactform2.MapIframeklink" name="MapIframeklink">
                    <small class="text-danger"[class.d-none]="MapIframeklink.untouched || MapIframeklink.valid">Map Link is required</small>
                </div>
           
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  [disabled]="!Contactform2.valid">اضافه</button>

                </form>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>