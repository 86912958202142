<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right"  *ngIf="currentSections">
          <h2 style="padding-bottom: 20px; margin-top:40px">Update Section</h2>

          
              <div class="form-holder">
                <p style="margin-top: 30px;">Question <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
                id="Question"
                [(ngModel)]="currentSections.question"
                name="Question"></textarea>
              </div>
              <div class="form-holder">
                <p style="margin-top: 30px;">Answer <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"   
                id="Answer"
                [(ngModel)]="currentSections.answer"
                name="Answer"></textarea>
              </div>
         
  
            

  
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;" routerLink="/FAQ" (click)="updateFAQSections()">Update</button>
                <br>  
          
        </div>
    </div>
  </div>