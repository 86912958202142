


<!-- <router-outlet *ngIf="check == 'flase'; else elseBlock"></router-outlet> -->

<!-- <ng-template #elseBlock>  -->
    <div class="container-scrollre" >
        <app-header></app-header>
        <app-sidebar></app-sidebar>
        
        <div class="content-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
<!-- </ng-template> -->