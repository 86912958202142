import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './pages/home/home.component';
import { AddHomesectionsComponent } from './pages/add-homesections/add-homesections.component';
import { UpdateHomesectionComponent } from './pages/update-homesection/update-homesection.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AddAboutusComponent } from './pages/add-aboutus/add-aboutus.component';
import { UpdateaboutComponent } from './pages/updateabout/updateabout.component';
import { OurServiceComponent } from './pages/our-service/our-service.component';
import { AddServicesComponent } from './pages/add-services/add-services.component';
import { UpdateServiceComponent } from './pages/update-service/update-service.component';
import { BuisnessComponent } from './pages/buisness/buisness.component';
import { AddBuisnessComponent } from './pages/add-buisness/add-buisness.component';
import { UpdateBuisnessComponent } from './pages/update-buisness/update-buisness.component';
import { BuisnessImagesComponent } from './pages/buisness-images/buisness-images.component';
import { AddImagesComponent } from './pages/add-images/add-images.component';
import { FAQComponent } from './pages/faq/faq.component';
import { AddFAQComponent } from './pages/add-faq/add-faq.component';
import { UpdateFAQComponent } from './pages/update-faq/update-faq.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AddContactComponent } from './pages/add-contact/add-contact.component';
import { UpdateContactComponent } from './pages/update-contact/update-contact.component';
import { LogInComponent } from './pages/log-in/log-in.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    HomeComponent,
    AddHomesectionsComponent,
    UpdateHomesectionComponent,
    AboutUsComponent,
    AddAboutusComponent,
    UpdateaboutComponent,
    OurServiceComponent,
    AddServicesComponent,
    UpdateServiceComponent,
    BuisnessComponent,
    AddBuisnessComponent,
    UpdateBuisnessComponent,
    BuisnessImagesComponent,
    AddImagesComponent,
    FAQComponent,
    AddFAQComponent,
    UpdateFAQComponent,
    ContactComponent,
    AddContactComponent,
    UpdateContactComponent,
    LogInComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
      
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
