<div class="row mb-2 justify-content-center " style="padding-top: 100px;width: 100%;margin: 0px;">
    <div class="col-lg-12" style="margin-bottom: 50px;">
      <div class="card ">
        <div class="card-body ">
          <h5 class="card-title mb-4 " style="display: inline-block;margin-right: 50px;">Service Section Table </h5>
          <button  class="btn btn-sm" style="color: white;background-color: #ceb98c;" (click)="addServicessections()">Add New Sction </button>
          <div class="table-responsive ">
            <table class="table center-aligned-table">
              <thead>
                <tr class="text-primary">
                  <th>Services Title</th>
                  <th>Service Paragraph</th>
                  <th>Service Image</th>
                  <th>update</th>
                  <th>delete</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let section of ServicesSectionList">
                  <td>{{section.ourServicesTitle}}</td>
                  <td style="word-break: break-all;">{{section.ourServicesParagraph}}</td>  
                  <td>
  <img [src]="createImgPath(section.ourServicesImage)" width="300" height="300">
</td>  
                 <td>   
                     <button style="border: none;border-radius: 5px;" class="btn-primary" (click)="updateServiceSections(section.id)" >Update</button> 
                 </td>
                 <td>
                 <button style=" border: none;border-radius: 5px;" class="btn-danger" (click)="deleteServiceSections(section.id)">Delete</button>

                 </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
   
  </div>
  <div class="row " style="align-items: center;">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
  </div>
  </div>
  