<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right"  *ngIf="currentSections">
          <h2 style="padding-bottom: 20px; margin-top:40px">Update Section</h2>

          
              <div class="form-holder">
                  <p style="margin-top: 30px;">Location <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"    
                  id="Location"
                  [(ngModel)]="currentSections.location"
                  name="Location" >
              </div>
              <div class="form-holder">
                <p style="margin-top: 30px;">Phone Number <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="PhoneNumber"
                [(ngModel)]="currentSections.phoneNumber"
                name="PhoneNumber" >
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">Email <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="Email"
                [(ngModel)]="currentSections.email"
                name="Email" >
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">Available Time <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="AvailableTime"
                [(ngModel)]="currentSections.availableTime"
                name="AvailableTime" >
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">Facebook Link <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="FacebookLink"
                [(ngModel)]="currentSections.facebookLink"
                name="FacebookLink" >
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">Instagram Link <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="InstagramLink"
                [(ngModel)]="currentSections.instagramLink"
                name="InstagramLink" >
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">LinkedIn Link <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="LinkedInLink"
                [(ngModel)]="currentSections.linkedInLink"
                name="LinkedInLink" >
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">Gmail Link <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="GmailLink"
                [(ngModel)]="currentSections.gmailLink"
                name="GmailLink" >
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">Map Embeded Link <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"    
                id="MapIframeklink"
                [(ngModel)]="currentSections.mapIframeklink"
                name="MapIframeklink" >
            </div>
         

  
                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;" routerLink="/Contact" (click)="updateContactSections()">Update</button>
                <br>  
          
        </div>
    </div>
  </div>