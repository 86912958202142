

<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                    Section Added
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">Add Header Section</h2>

              <div class="form-holder">
                  <p>Header Title <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"  [class.is-invalid]="Title.invalid && Title.touched " [class.is-invalid]="Title.invalid && Title.touched " required #Title="ngModel" [(ngModel)]="homepgesection.HeaderTitle" name="Title">
                  <small class="text-danger"[class.d-none]="Title.untouched || Title.valid">Title is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">Header Pragraph <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="Pragraph.invalid && Pragraph.touched " [class.is-invalid]="Pragraph.invalid && Pragraph.touched " required #Pragraph="ngModel" [(ngModel)]="homepgesection.HeaderParagraph" name="Pragraph"></textarea>
                <small class="text-danger"[class.d-none]="Pragraph.untouched || Pragraph.valid">Pragraph is required</small>
              </div>

              <h2 style="padding-bottom: 20px; margin-top:40px">Add Service Section(1) </h2>

              <div class="form-holder">
                  <p style="margin-top: 30px;">Service Title <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"  [class.is-invalid]="ServiceTitle.invalid && ServiceTitle.touched " [class.is-invalid]="ServiceTitle.invalid && ServiceTitle.touched " required #ServiceTitle="ngModel" [(ngModel)]="homepgesection.SectionOneServicesTitle" name="ServiceTitle">
                  <small class="text-danger"[class.d-none]="ServiceTitle.untouched || ServiceTitle.valid">City is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">Service Pragraph <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="ServicePragraph.invalid && ServicePragraph.touched " [class.is-invalid]="ServicePragraph.invalid && ServicePragraph.touched " required #ServicePragraph="ngModel" [(ngModel)]="homepgesection.SectionOneServicesParagraph" name="ServicePragraph"></textarea>
                <small class="text-danger"[class.d-none]="ServicePragraph.untouched || ServicePragraph.valid">Service Pragraph is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">Upload Image One <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file" 
                     class="form-control border-0" #file type="file" 
                     (change)="uploadFile($event.target.files[0])" 
                     multiple 
                      [(ngModel)]="homepgesection.SectionOneServicesImageOneLeft"  >
                </div>
                <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
               </div>

        
       
               <h2 style="padding-bottom: 20px; margin-top:40px">Add Contact Section(2) </h2>

               <div class="form-holder">
                   <p style="margin-top: 30px;">Works Hour <span style="color: #6b7a42;"> *</span></p>
                   <input type="text" class="form-control"  [class.is-invalid]="WorksHour.invalid && WorksHour.touched " [class.is-invalid]="WorksHour.invalid && WorksHour.touched " required #WorksHour="ngModel" [(ngModel)]="homepgesection.SectionTwoWorksHour" name="WorksHour">
                   <small class="text-danger"[class.d-none]="WorksHour.untouched || WorksHour.valid">Works Hour is required</small>
               </div>
               <div class="form-holder">
                <p style="margin-top: 30px;">Adress <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  [class.is-invalid]="Adress.invalid && Adress.touched " [class.is-invalid]="Adress.invalid && Adress.touched " required #Adress="ngModel" [(ngModel)]="homepgesection.SectionTwoGetDirection" name="Adress">
                <small class="text-danger"[class.d-none]="Adress.untouched || Adress.valid">Adress is required</small>
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">Call Us <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  [class.is-invalid]="Call.invalid && Call.touched " [class.is-invalid]="Call.invalid && Call.touched " required #Call="ngModel" [(ngModel)]="homepgesection.SectionTwoCallUs" name="Call">
                <small class="text-danger"[class.d-none]="Call.untouched || Call.valid">Call info is required</small>
            </div>
            <h2 style="padding-bottom: 20px; margin-top:40px">Add About Us Section(3) </h2>

            <div class="form-holder">
                <p style="margin-top: 30px;">About Us Title <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  [class.is-invalid]="About.invalid && About.touched " [class.is-invalid]="About.invalid && About.touched " required #About="ngModel" [(ngModel)]="homepgesection.SectionThreeAboutUsTitle" name="About">
                <small class="text-danger"[class.d-none]="About.untouched || About.valid">Title is required</small>
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">About Us Pragraph <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="AboutPragraph.invalid && AboutPragraph.touched " [class.is-invalid]="AboutPragraph.invalid && AboutPragraph.touched " required #AboutPragraph="ngModel" [(ngModel)]="homepgesection.SectionThreeAboutUsParagraph" name="AboutPragraph"></textarea>
              <small class="text-danger"[class.d-none]="AboutPragraph.untouched || AboutPragraph.valid">Paragraph is required</small>
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">About us Image  <span style="color: #6b7a42;"> *</span></p>
              <!-- (change)="uploadFile(file.files)" -->
              <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                  <input id="upload" type="file" 
                   class="form-control border-0" 
                   #file type="file" 
                   (change)="uploadFile($event.target.files[0])" 
                   multiple 

                    [(ngModel)]="homepgesection.SectionThreeAboutUsImage">
              </div>
              <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
             </div>

                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  (click)="OnSubmit()">Add</button>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>

<div class="container" style="padding-top: 120px; padding-bottom: 80px;">

    <div class="row justify-content-center content-my-account">
        <div class="col-md-6 col-sm-12 col-12 content-my-account-right">
              <div *ngIf="showSuccess; else thenfaild">
                <div class="alert alert-success" role="alert">
                   اضافه اللغه العربيه
                </div>
               </div>
               <ng-template #thenfaild></ng-template>
              
              <div *ngIf="showError; else elseBlock">
                <div class="alert alert-warning" role="alert">
                    {{errorMessage.error | json}}
                </div>
              </div>
              <ng-template #elseBlock></ng-template>
              <h2 style="padding-bottom: 20px;">الواجهه</h2>

              <div class="form-holder">
                  <p>عنوان الواجهه <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"  [class.is-invalid]="Title.invalid && Title.touched "
                   [class.is-invalid]="Title.invalid && Title.touched "
                    required #Title="ngModel" [(ngModel)]="homepgesectionAR.HeaderTitle" name="Title">
                  <small class="text-danger"[class.d-none]="Title.untouched || Title.valid">Title is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">فقره الواجهه <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="Pragraph.invalid && Pragraph.touched " [class.is-invalid]="Pragraph.invalid && Pragraph.touched " required #Pragraph="ngModel" [(ngModel)]="homepgesectionAR.HeaderParagraph" name="Pragraph"></textarea>
                <small class="text-danger"[class.d-none]="Pragraph.untouched || Pragraph.valid">Pragraph is required</small>
              </div>

              <h2 style="padding-bottom: 20px; margin-top:40px">الجزء الاول </h2>

              <div class="form-holder">
                  <p style="margin-top: 30px;">عنوان الجزء الاول <span style="color: #6b7a42;"> *</span></p>
                  <input type="text" class="form-control"  [class.is-invalid]="ServiceTitle.invalid && ServiceTitle.touched " [class.is-invalid]="ServiceTitle.invalid && ServiceTitle.touched " required #ServiceTitle="ngModel" [(ngModel)]="homepgesectionAR.SectionOneServicesTitle" name="ServiceTitle">
                  <small class="text-danger"[class.d-none]="ServiceTitle.untouched || ServiceTitle.valid">City is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">فقره الجزء الاول <span style="color: #6b7a42;"> *</span></p>
                
                <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="ServicePragraph.invalid && ServicePragraph.touched " [class.is-invalid]="ServicePragraph.invalid && ServicePragraph.touched " required #ServicePragraph="ngModel" [(ngModel)]="homepgesectionAR.SectionOneServicesParagraph" name="ServicePragraph"></textarea>
                <small class="text-danger"[class.d-none]="ServicePragraph.untouched || ServicePragraph.valid">Service Pragraph is required</small>
              </div>

              <div class="form-holder">
                <p style="margin-top: 30px;">تحميل صوره الجزء الاول <span style="color: #6b7a42;"> *</span></p>
                <!-- (change)="uploadFile(file.files)" -->
                <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                    <input id="upload" type="file" 
                     class="form-control border-0" #file type="file" 
                     (change)="uploadFile($event.target.files[0])" 
                     multiple 
                      [(ngModel)]="homepgesectionAR.SectionOneServicesImageOneLeft"  >
                </div>
                <small style="margin-left: 15px;" class="text-danger">This Image is required</small>
               </div>

        
       
               <h2 style="padding-bottom: 20px; margin-top:40px"> الجزء الثاني</h2>

               <div class="form-holder">
                   <p style="margin-top: 30px;">ساعات العمل <span style="color: #6b7a42;"> *</span></p>
                   <input type="text" class="form-control"  [class.is-invalid]="WorksHour.invalid && WorksHour.touched " [class.is-invalid]="WorksHour.invalid && WorksHour.touched " required #WorksHour="ngModel" [(ngModel)]="homepgesectionAR.SectionTwoWorksHour" name="WorksHour">
                   <small class="text-danger"[class.d-none]="WorksHour.untouched || WorksHour.valid">Works Hour is required</small>
               </div>
               <div class="form-holder">
                <p style="margin-top: 30px;">العنوان <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  [class.is-invalid]="Adress.invalid && Adress.touched " [class.is-invalid]="Adress.invalid && Adress.touched " required #Adress="ngModel" [(ngModel)]="homepgesectionAR.SectionTwoGetDirection" name="Adress">
                <small class="text-danger"[class.d-none]="Adress.untouched || Adress.valid">Adress is required</small>
            </div>
            <div class="form-holder">
                <p style="margin-top: 30px;">تواصل معنا <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  [class.is-invalid]="Call.invalid && Call.touched " [class.is-invalid]="Call.invalid && Call.touched " required #Call="ngModel" [(ngModel)]="homepgesectionAR.SectionTwoCallUs" name="Call">
                <small class="text-danger"[class.d-none]="Call.untouched || Call.valid">Call info is required</small>
            </div>
            <h2 style="padding-bottom: 20px; margin-top:40px">الجزء الثالث </h2>

            <div class="form-holder">
                <p style="margin-top: 30px;">عنوان الجزء الثالث <span style="color: #6b7a42;"> *</span></p>
                <input type="text" class="form-control"  [class.is-invalid]="About.invalid && About.touched " [class.is-invalid]="About.invalid && About.touched " required #About="ngModel" [(ngModel)]="homepgesectionAR.SectionThreeAboutUsTitle" name="About">
                <small class="text-danger"[class.d-none]="About.untouched || About.valid">العنوان مطلوب</small>
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">فقره الجزء الثالث <span style="color: #6b7a42;"> *</span></p>
              
              <textarea name="" id="" cols="30" rows="10"  type="text" class="form-control"  [class.is-invalid]="AboutPragraph.invalid && AboutPragraph.touched " [class.is-invalid]="AboutPragraph.invalid && AboutPragraph.touched " required #AboutPragraph="ngModel" [(ngModel)]="homepgesectionAR.SectionThreeAboutUsParagraph" name="AboutPragraph"></textarea>
              <small class="text-danger"[class.d-none]="AboutPragraph.untouched || AboutPragraph.valid">الفقره مطلوبه</small>
            </div>

            <div class="form-holder">
              <p style="margin-top: 30px;">تحميل صوره الجزء الثالث <span style="color: #6b7a42;"> *</span></p>
              <!-- (change)="uploadFile(file.files)" -->
              <div class="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                  <input id="upload" type="file" 
                   class="form-control border-0" 
                   #file type="file" 
                   (change)="uploadFile($event.target.files[0])" 
                   multiple 

                    [(ngModel)]="homepgesectionAR.SectionThreeAboutUsImage">
              </div>
              <small style="margin-left: 15px;" class="text-danger">الصوره مطلوبه</small>
             </div>

                <button type="submit"  class="bt" style="background-color: burlywood;
                color: aliceblue;
                border: none;
                padding: 10px 40px 10px 40px;
                font-size: medium;
                text-align: unset;
                margin-top: 40px;"  (click)="addArabicSection()">اضافه</button>
                <br>  
                <!-- <div class="row justify-content-center content-my-account">
                    <a  href="/category" class="" style="color: #ffa6a8;">Show All Categories</a>
                </div>               -->
                
        </div>
    </div>
</div>