import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { homepage } from 'src/app/models/homepage';
import { HomeService } from 'src/app/Services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  constructor(private homeservices:HomeService , private router:Router) {

   }
  
  homeSections:homepage[]=[];
  homeSectionList:homepage[]=[];
  checkList:homepage[]=[]

  id1:number;
  id2:number;

  CurrentHomrSection =null;
  ngOnInit(): void {
    // if(localStorage.getItem('valid')== 'flase'){
    //   this.router.navigate(['/LogIn'])
    //   .then(() => {
    //     window.location.reload();
    //   });
    // }
     
         this.GetAllHomeSection();
    
  }
  GetAllHomeSection()
  {
    this.homeservices.getHomeSections().subscribe((data:any)=>{
      this.homeSections = data;
      this.checkList=data;
      this.id1=data[0].id;
      this.id2=data[1].id;

      this.homeSections.forEach(section => {
        
          this.homeSectionList.push(section);
      });
    });
  }
  addhomesection(){
    this.router.navigate(['/addhomesection']);

  }
  
  deleteHomeSections() {
    this.homeservices.deleteHomeSections(this.id1).subscribe();
    this.homeservices.deleteHomeSections(this.id2).subscribe();

    alert("the sections deleted");
    
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/Home']);
  }
  
  updateHomeSections(): void {
    this.router.navigate(['/updatehomesection',this.id1]);
      // this.homeservices.updateCategory(this.id, this.CurrentHomrSection)
      //   .subscribe()
     
    }

  
    
    updateHomeSectionsAR(): void {
      this.router.navigate(['/updatehomesection',this.id2]);
        // this.homeservices.updateCategory(this.id, this.CurrentHomrSection)
        //   .subscribe()
       
      }
  

  

   public createImgPath = (serverPath: string) => {
    return `https://api.complete-energy.co/${serverPath}`;
  }
  public response: {dbPath: ''};
  onCreate()
  {
    this.CurrentHomrSection = {
      sectionOneServicesImageOneLeft: this.response.dbPath,
      sectionOneServicesImageTwoRight : this.response.dbPath,
      sectionOneServicesImageThreeRight :this.response.dbPath,
      sectionThreeAboutUsImage :this.response.dbPath

    }
  }
  public uploadFinished = (event) => {
    this.response = event;
  }
}
